<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="contentwrap">
        <div class="leftaside">
          <div class="imgbox">
            <img-upload
              @change="getImg"
              class="getimgbtn"
              :ffixed="true"
              :ffixedNumber="[1,1]"
            ></img-upload>
            <img :src="avatar" class="img" v-if="avatar" />
            <!-- <img class="img" src="../../../assets/imgs/minbanner2.png" /> -->
          </div>
          <div class="pername"  v-text="form.username">
            <!-- <i class="iconfont iconbianji2 blue"></i> -->
          </div>
          <p
            class="centerp"
           v-text="userinfo.Intro"></p>
          <ul class="leftlist">
            <router-link :to="{name:'persionTeam'}" tag="li">团队</router-link>
            <router-link :to="{name:'persionBookMy'}" tag="li">图书</router-link>
            <router-link :to="{name:'persionColumnMy'}" tag="li">专栏</router-link>
            <router-link :to="{name:'order'}" tag="li">交易</router-link>
            <router-link :to="{name:'persionSet'}" tag="li">设置</router-link>
          </ul>
        </div>
        <div class="rightmain">
          <router-view></router-view>
        </div>
      </div>
      <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
import { getUserPerson,uploadsAvatarRequest} from "@/api/project.js";
import { setAvatar } from "@/utils/auth.js";
import ImgUpload from "@/components/Imgupload/imgUpload";

export default {
  components: {
    NavMain,
    FooterAll,
    ImgUpload
  },
  data() {
    return {
       avatar: "",
      userinfo: {},
      form: {
        username: "",
        phone: "",
        email: "",
        password: "",
        intro: ""
      },
      blob: "",
    };
  },
  created(){
    this.getUserPerson();
  },
  methods: {
    async getUserPerson() {
      try {
      const user = await getUserPerson().then(res => res.data)
      this.avatar = user.avatar;
      this.userinfo = user.userinfo;

      let { Username, Phone, Email, Intro } = user.userinfo;
      this.form.username = Username;
      this.form.phone = Phone;
      this.form.email = Email;
      this.form.intro = Intro;
       }catch(e){};
    },
    async saveImg() {
      try {
        var formData = new FormData();
        formData.append('file', this.blob );
        const result = await uploadsAvatarRequest(formData);
        if (result.status === 200) {
          this.$store.commit("setAvatar", result.link);
            setAvatar(result.link)
           this.$message({
            showClose: true,
            message: '头像上传成功',
            type: "success"
          });
        }
        else{
          this.$message({
            showClose: true,
            message: '头像上传失败',
            type: 'error'
          });
      }
      } catch (e) {}
    },
    getImg(url, data) {
      this.avatar = url;
      this.blob = data;
      this.saveImg()
    }
  },
};
</script>
<style lang="less" scoped>
.el-container {
  flex-direction: column;
   height: 100%;
  .el-main {
    padding: 0;
    .contentwrap {
      display: flex;
      width: 1200px;
      margin: 30px auto;
      padding: 50px 0;
      box-shadow: 0 0px 10px #ccc;
    }
    .leftaside {
      width: 300px;
      padding: 0 50px;
      box-sizing: border-box;
      border-right: 1px solid #e2e2e2;
      .imgbox {
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin: 0 auto 30px;
      position: relative;
      border-radius: 200px;
      .getimgbtn {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 5;
        /deep/.el-upload-dragger {
          width: 100px;
          height: 100px;
          line-height: 100px;
          border-radius: 200px;
        }
      }
      /deep/.el-upload-dragger .iconfont{
        line-height: 100px;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        z-index: 1;
      }
    }
      .pername {
        text-align: center;
        font-size: 18px;
        line-height: 40px;
        margin: 30px 0;
      }
      .blue {
        color: #2f95e3;
      }
      .centerp {
        font-size: 14px;
        line-height: 26px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 30px;
      }
      .leftlist {
        li {
          border: 1px solid #e2e2e2;
          text-align: center;
          margin-bottom: 5px;
          height: 36px;
          line-height: 36px;
          font-size: 16px;
          color: #666;
          cursor: pointer;
          &:hover {
            background: #2f95e3;
            color: #fff;
          }
        }

        .active {
          background: #2f95e3;
          color: #fff;
        }
      }
    }
    .rightmain {
      flex: 1;
    }
  }
}
</style>